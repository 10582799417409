import "lazysizes";
import { ProportionalImageNormalisation } from "@keodesign/proportional-image-normalisation";
import { FloatLabels } from "@keodesign/float-labels";
import { initialiseResponsiveTables } from "@keodesign/responsive-tables/table-responsive.js";
var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");

export function setupSlideMenu(){

	$("#mobile-menu, .slide-menu h3").click(function(){
		$("body").toggleClass("slide-menu-active");
	});

	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});

	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});

window.onload = function() {
	setupSlideMenu();	
	ProportionalImageNormalisation(".additional_logos img", 40, 0.7);
	if (document.querySelector("[data-post-load=\"testimonials\"]")) {
		import(/* webpackChunkName: "testimonials" */ "./testimonials.js").then(testimonials =>{
			testimonials.initialise();
		});
	}
	if (document.querySelector(".contact-form")) {
		new FloatLabels(".contact-form form .js-float-wrap");		
	}	
	initialiseResponsiveTables();
};